import { Placement } from "@popperjs/core";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  position?: Placement;
}

const Tooltip = ({ children, content, position = "top" }: TooltipProps) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: position,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8], // [skidding, distance]
        },
      },
    ],
  });

  const elemPortal = document.getElementById("popperPortal");

  return (
    <span className="inline-block">
      <span
        ref={setReferenceElement as any}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </span>
      {elemPortal &&
        isVisible &&
        ReactDOM.createPortal(
          <div
            ref={setPopperElement as any}
            style={styles.popper}
            {...attributes.popper}
            className="z-50 px-2 py-1 text-sm text-white bg-gray-900 rounded-md shadow-sm"
            role="tooltip"
          >
            {content}
            <div
              className="tooltip-arrow"
              data-popper-arrow
              style={styles.arrow}
            />
          </div>,
          elemPortal
        )}
    </span>
  );
};

export default Tooltip;