import imgMacadamia from "../assets/macadamia-100.jpg";
import imgAlmendra from "../assets/almendra-100.jpg";
import imgMaranon from "../assets/maranon-100.jpg";
import imgCocoa from "../assets/cocoa-100.jpg";
import imgMani from "../assets/mani-100.jpg";
import imgPink from "../assets/pink-100.jpg";
import imgMatcha from "../assets/matcha-100.jpg";
import imgGolden from "../assets/golden-100.jpg";
import imgCanela from "../assets/canela-100.jpg";
import imgPistacho from "../assets/pistacho-100.jpg";

const THE_PRODS = [
  {
    id: "20",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Negociación por insolvencia",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 1,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      unit: "service",
    },
    tax: [],
    customFields: [],
    productKey: null,
    type: "service",
    itemType: null,
  },
  {
    id: "21",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "ICUI pendiente de cobro por cambio de tarifa del 10 % al 15 %",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 1,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      unit: "service",
    },
    tax: [],
    customFields: [],
    productKey: null,
    type: "service",
    itemType: null,
  },
  {
    id: "25",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Pistacho 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 25714.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 25714.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 25378.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 32000,
      initialQuantityDate: "2024-07-15",
      unit: "unit",
      unitCost: 18024,
      availableQuantity: 30576,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 32000,
          availableQuantity: 30576,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "22",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Marañón Cocoa 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 16555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 16555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 16134.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 5000000,
      initialQuantityDate: "2024-04-01",
      unit: "unit",
      unitCost: 9122,
      availableQuantity: 4999421,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 5000000,
          availableQuantity: 4999421,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "23",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Marañón Canela 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 16555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 16555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 16134.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 50000,
      initialQuantityDate: "2024-04-01",
      unit: "unit",
      unitCost: 10006,
      availableQuantity: 49527,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 50000,
          availableQuantity: 49527,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "16",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Marañón 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 15042.2,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 14874.2,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 15042.2,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 20000,
      initialQuantityDate: "2022-08-08",
      unit: "unit",
      unitCost: 8750,
      availableQuantity: 4204,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 20000,
          availableQuantity: 4204,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "19",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Maní 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 5798.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 5546.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 5798.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 20000,
      initialQuantityDate: "2022-08-08",
      unit: "unit",
      unitCost: 2820,
      availableQuantity: 9068,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 20000,
          availableQuantity: 9068,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "24",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Macadamia Pinkfud 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 23109.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 23109.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 22773.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 50000,
      initialQuantityDate: "2024-04-01",
      unit: "unit",
      unitCost: 13875,
      availableQuantity: 49221,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 50000,
          availableQuantity: 49221,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "17",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Macadamia 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 15714.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 15210.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 15714.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 20000,
      initialQuantityDate: "2022-08-08",
      unit: "unit",
      unitCost: 7446,
      availableQuantity: 7816,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 20000,
          availableQuantity: 7816,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "18",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Almendra 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 13782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 12521.5,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 13782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 20000,
      initialQuantityDate: "2022-08-08",
      unit: "unit",
      unitCost: 5365,
      availableQuantity: 7504,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 20000,
          availableQuantity: 7504,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "26",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Pistacho 1Kg",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 196332,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 10000,
      initialQuantityDate: "2024-09-09",
      unit: "unit",
      unitCost: 137432,
      availableQuantity: 9991,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 10000,
          availableQuantity: 9991,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "14",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Nueces 32g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 2941.19,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 100000,
      initialQuantityDate: "2020-12-11",
      unit: "unit",
      unitCost: 1765,
      availableQuantity: 99860,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 100000,
          availableQuantity: 99860,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "5",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Marañón Cocoa 240g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 36891,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 36303,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "3",
        name: "PVP",
        type: "amount",
        price: 30252,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 36891,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 10000000,
      initialQuantityDate: "2019-10-04",
      unit: "unit",
      unitCost: 22528,
      availableQuantity: 9990525,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 10000000,
          availableQuantity: 9990525,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 12,
        name: "Imagen4.png",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594574048-Imagen4.png?Expires=1738026650&Signature=pkSWwLgg-dA5hxh5~4cvOFY4opd~4lyCvj-C57gBzPE1AJmD--R04gNsiJxqbKh-5nxYeau6I0oNx~tpG80jdiCY-l17XT33VQlJxP4Ze5z2DK0LIx4DW3~zauwX0U3FVN4F3FiRkWprV0-Xc~Src0Hq49b0AIKXZ4kGQZ0VOpq6-Owm9gWp2UTeuOhTUkjI3q6gkNPaslo-PxsbXxmGd8tiZzoyZTo-~4JYap~6ohPyEFFDKkiK5Xi9C-DX5zAPHl2tdV4xSrCedz4rUOF6NKlmCF8F~uJck4kcuYw5PCJ3CrkMa320gOcPUX3PewMsnEz38XomgVAlhVdqH844Bg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 20,
        name: "maranoncocoa_10ee8637.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575358-maranoncocoa_10ee8637.jpg?Expires=1738026650&Signature=A~fGaxD8FRIus5l9rPDuHYdMKFQWEu4gjAPcoMMTUxpaJo0~Xm2ipHYz81W2Wv3VfR8Iy6SVNxgQpHjgw~TXueD27N391r0BT~39cAXyrZxgcrBEpQO35OPUJ4utKdCFVEpgyHC6BCirjzSnTCFgUiopPg77bnISr0pBW2VCpndVHcGCdbJn8KQNcGNnBrvmn3y2WNJElhr8vadAmZmxXIxSr726l4V0K4jD4Ypfmk8IVoTm~kRoNEhljtfRC7p44sA5s4tQR4f8BgOYot~hzaPsTdlq0G-Zb5Fi22GR9mccN~G423KBSo2QkPsNk4v~ePm6Cjs0XtDDkJY4jqyQ4A__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "13",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Marañón Canela 240g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 36891,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 36303,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "3",
        name: "PVP",
        type: "amount",
        price: 35100,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 36891,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000000,
      initialQuantityDate: "2020-09-20",
      unit: "unit",
      unitCost: 25079,
      availableQuantity: 999994504,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000000,
          availableQuantity: 999994504,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 21,
        name: "marañoncanela 2020-09-20 at 2.56.09 PM.png",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1600632668-mara%C3%B1oncanela+2020-09-20+at+2.56.09+PM.png?Expires=1738026650&Signature=SZIRbHVo-eYIRB-nNqZ~AuEsw~LlWSTdBuMF1Julcyv1JIEfY-UT1Dpg-UGQFI~z4z~ncDW5sKPqh9t1l~40scYbEnbu5PUh6rzdyslDutrkwH8zj9q1Zro4TzcyATUbha6hXBHIktdEaYLP0qV4iaxAXK8-mKV5i-ZCAwxOH3QqcLbXOzRxROfrFUBv2b5pAyrW2RgDi0KRlI0mtFoTUzdQAasCzG3G-Zn16a5EarnHZ119O~EGMNEaITEDM3qQl6X5xySSWvBAM4tof-GSqEQ22oDCNuMcdp9g6axiFaMw5I3BF6uS0iWKhG5ZL9S98LdtTOMi1661Mrpj4~uczg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "3",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Marañón 240g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 33782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 33277.99,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "3",
        name: "PVP",
        type: "amount",
        price: 26555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 33782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-04",
      unit: "unit",
      unitCost: 21712,
      availableQuantity: 957614,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 957614,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 1,
        name: "Marañon.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1593882160-Mara%C3%B1on.jpg?Expires=1738026650&Signature=ZewKHUiHeV01SuUBBm~rbFulvjStgR2vuqWmH8HjFDY9oYCrGGzUIADB5lGwzZKojUr-MJNGHfUH87f0e8GUZro4MyWJG~xPIKC7vzxRl-jovfq0SptSz7J0m6CXXnYGe8bRtyELs7~~YKB7sC3S4qNJRLUgC4e4p-TxwDofxu6kl9ifNksEVLNgvGDSdwqZSpV7Us10AZr4HAMwN54eGHpsGBGNQn6lHUOr7WWwY6liRnivP9X540I2AiyCna-GjczHX-rdgVoQgJ6QRQ0sVKCG7EcyxyejTIH~msfqbKafpATE-SybkH3qT36~Vz~KWa~pxEAUiXKVm93v75L1tg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 19,
        name: "maranon_1270bf70.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575349-maranon_1270bf70.jpg?Expires=1738026650&Signature=dMqqjM3I-Omfec36IDMMklq7lp3JrpH2nzht2AXDtJlzL6SEDEcERN5RTLPV4-zqsWhHV~DdV4cg6L6TMyg4JtDLcuD1ClSo2Vl8lCj27mrbT~uxZQ-Gm8BC03sBDsaL7~lQxDWiYbu2gYEepML2MW6Ql0OiD5Gx05IPFEYyj0x9MXzxi3JiZE3fIeT3C81RqcGoi0BoLydf1Z3g1L2xdwho4PWgRFV79R5IIahnhrBDCn-cxCyfmAZaR1gANyf4jwzo5N~N0Q99uWhzqAVSulJs8nVk9uoqdCmRMBmRwwxLAJBmHIB4NRrSET0snjtOKHU650ykCVJE85od9dtIpw__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "15",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Marañon 1kg",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 90000,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      unit: "unit",
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "simple",
    itemType: null,
  },
  {
    id: "9",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Maní 500g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 16387,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 1000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 9685,
      availableQuantity: 874,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000,
          availableQuantity: 874,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "simple",
    itemType: null,
  },
  {
    id: "4",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Maní 240g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 12941.25,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 12269.25,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "3",
        name: "PVP",
        type: "amount",
        price: 10924,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 12941.25,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 7480,
      availableQuantity: 963596,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 963596,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 7,
        name: "Mani.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076973-Mani.jpg?Expires=1738026650&Signature=m0Dk8kKMz8poeycj7rdYT3OC-p3wUnxZALGjlKo2W5qev2~ZqDQnSWC7dbE85KqWtteKO1cYv4Dz84PeeX21u2janSnai8zfoxJqPJZAmjG0kr5nop8eDzNWsFpKnsW8PnvotPZ-iPMRuAVodbpqqV3-xg7j6hjDPyVaI0eog8CI49pAfPyABhep~Y-POvPLN~vyZYMW6PqsE~P9JKSCtpyDbHcFQLN9WE-bF6LTb4t2kk63-TFwzp0kjkcYASYmCaNJgeps1sRYe1-isk4H10X50a0Wx6CtM9X9UxOAWpukp2XBX0dFXh~eYhZLi~lHHrvgAzU7x3SljF2qwl6~YQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 18,
        name: "mani_78354541.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575340-mani_78354541.jpg?Expires=1738026650&Signature=VaMp0cJHMJuNxsXlIU3-hECs42X~fY18v8XGREptYBREkVPn9W1Ffs5qu8lhhOcyCtGjvZmPvh57CbkLfe5ilbDJg9vyAgzVAwD6I0B37JGmVbwiq-lmKUr0VVPOYRA2tKeuE38tj0pWRPPSbm7hnZWupNWHfnVBTyllCTjrnqN1I2H5bseKt8DHFygxOQWPQdkDamjFkDxlHCaOQgT-gEvUEikydW1luWTmtqOms0OMYZMB~d3OhIbXOkjzZvyo~A7f5sT3L-wM-zUyfMCjAgQfhE7CIyjtY5vcn2iAE6bmNms5suduMAkgl76nJvR3gSGrOLCFyet1ln-zSbmlXA__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "12",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Maní 1Kg",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 36050.42,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2020-07-12",
      unit: "unit",
      unitCost: 20875,
      availableQuantity: 999488,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 999488,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "6",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia Pinkfud 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 21932.77,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 21932.77,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "3",
        name: "PVP",
        type: "amount",
        price: 20168,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 21932.77,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 10000000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 14968,
      availableQuantity: 9988219,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 10000000,
          availableQuantity: 9988219,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 6,
        name: "Pink.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076956-Pink.jpg?Expires=1738026650&Signature=UsErSldK7L3XIkF43YZIrdd5Vd-Cmu23PwJlaJ~1~uJ4W0F1El2qV2QFHS89GosyMZixs~t4Fbhl7L418tlV4kG8kLLqdMndEYnGLnZULqGNT~7rfGuU4hdxrHpRz0dXGYYcxg05x2AYho-o0Iei4ejU~BkRYwGoZwxKTh5mwKTfvkNYvGaovUbRKNrWd843eyFD02gfa-xTmIdTpo2s0iTIJcGubXu~qOyavu9~x~rMxDb1Juz0CaNXJeRP7~xI-nxZ7b7mrATFFGskYIm3wyuq4SVim9lBSotxXDE4W~Pfgc9z6F1vFC~Xm0Z8fivDRMTymdcSbL8RRAH8hGSgyg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 17,
        name: "macadamia-pinkfud_45ab54cc.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575328-macadamia-pinkfud_45ab54cc.jpg?Expires=1738026650&Signature=JObIUae3POCWgdfz7m~U44rNLsobjst6vFexY4NIXLlZjtpTKGYAhw5qy-A1X1XbdVnCaWvV~TaxXY0PRXIzH-kXbLJH2WBIsGx5zJwcpqEJRIjB94UQLzWZjf9K0~I1Z0KANy3QzKjoHr91XnSW05e~wpzlQ~Htex-eSwxpw4AeMKbdSLIcvMnvlRdP4oUVydrf4Qj5Z15zoIT57Zcua2V-nfzbgFEm-RFsqfQzvjfcecQYQqAb65CPreLYuv7dGpuGVGthfGpxRqyBFOd79oA65o4oXNcv3-Kx8EVY7pLj0IRDIStYB38FbjIrJnm2egqTUl0xSBcFKiE4Oc-zDA__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "10",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia Matchachá 500g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 53782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 1000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 34685,
      availableQuantity: 995,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000,
          availableQuantity: 995,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "simple",
    itemType: null,
  },
  {
    id: "7",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia Matchachá 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 23950,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 23950,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "3",
        name: "PVP",
        type: "amount",
        price: 20168,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 23950,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 100000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 11161,
      availableQuantity: 97497,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 100000,
          availableQuantity: 97497,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 5,
        name: "IMG_20200622_145520.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076908-IMG_20200622_145520.jpg?Expires=1738026650&Signature=lWQmUVfJR-G97rlg8lx8ixJIhHkC1yyEkav3nuGH04keEhKk46Q5sd9mbJgf2ylQ3PASFcJwbHRIR50ITKIR0vfcY8GBrPMjKxucKlA87jW~ejAJRaZlrE9s-GxqMv~iLP9ZBr1CsZePAUrbCHBwQF1jM~H5nqNp3wPu8zBcBdQrVObzS5IQYMh6QmX2cvlGai16jFvdKFsgqp0-q3TrUL1etRSnyRZCh0c-YDk7gO6KcOhISA1CdqH34qozK-ucGJalUjc9XIo0GUgW5bASxMAlEsEnj7gNU7evypBCy1UHlamZQZLp-~ndnzEHALCMOfPLM1YKujMbYujFadh1ng__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 8,
        name: "Matcha.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594077024-Matcha.jpg?Expires=1738026650&Signature=GB5p37n6XE6hoXfmiSteCmGFL~Q04P4-3WTKiEEK6XQqBfAvNeFOPBnDsfCwB9MKiORvVkGRMLxV2f6EznJZgx5VjMfRRdlwV9zuIFIvlZIk0rta9r~NXkLf1uGDI~suN4gnM6-J3fM3M1MlHs20-68ug4sHu4XK0b5P6h4YzJFvsnPrQn-k1MGW6VoyFBFUB6igqXCQ06G5HIZUNLdh~RXAAj0ZlBaJafORX0XtYWljRt-L-Eo-00~SXgP-VDtQSd2L~JJl-Q-9K3JgJBE7oXjSry4qdZOCgw9ZeYSyUVvYsNIubKHjopankaTSm2PGr0YwcO4~B~WYQPDR9nq1bA__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 9,
        name: "Matcha.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594077114-Matcha.jpg?Expires=1738026650&Signature=GnkCpsyCZA5qKBfWcc6PvIWgKkRU5oy0jO86eDbr5QA4ybnrXd7z5A69PEqeFeMZJsuqetH9pqrQa16gmegMr~xLndbNTKjOU2CahfofjRTII50T~0nWp5v8b1lafIHObpCKP5gSBDzWKcKCtaYQEY-FEOQFt8dF1kwEm5ktmnyxdwkr~gRGCVzAyCoC4lzKztMAYGgfPuMfxcOxry0hgIw7jBJcIauCPIXuMxvt1laIZ7dJM3SW6vYse0GhixW~xpJy7tRH3l81cslSuqEaJ4AO5bv3U8czF9aSivBFUtq~MZ2IWA8tx2IQsjuY3s6xbdKw5x1k54E5McFdd~tTiA__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 10,
        name: "Matcha.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594077215-Matcha.jpg?Expires=1738026650&Signature=G3tqW6oo93b-yhRe-99oGS~Fgc1VxZbh3BSYefpxkvuy1JbiOBilMyZSjRKoIZ5YvSl6OuIzNrimHKOrdG8nR4QRPW0JZKmvTpJ1B98fkWAonju4433GsLc34M-uyl5HIYJgjSZIumtz2VSRg4RYnxYDvZGKEyURM6-725DTQo1uq8O3BwqdXUVrADKOoH7ZuNyaUITG4wbaZJ~dmijNlOCHEZDuZ~ta8BUtbWEIEsFjbHisQrHlk7dBFwl2BqlOxH148EvnMvGjoVGXQ71JgYbrFhfoWg4assTFxE4iAVKk5w9wMO1dDNIw-aMmbvR6R3opusLpnAHcKpeshNeqJg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 16,
        name: "macadamia-matcha_966cbdef.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575319-macadamia-matcha_966cbdef.jpg?Expires=1738026650&Signature=JtJxYWalBerwImVgpFUpnAOTWF64sP10G7xB01tKAg~sKXKlE2DfwQpRu4rzezNPn-ZX2-06F2qz~XpCb63OQvbP9uX-NdJElRTHgaTg1J8e59iNvd2BJTcEba8bPfgs7-G~j8VtNTAUFUdlWy5uG~4t3gtPCAh~94x1ixODjvRo0BGzXzDNF8kO6CT77~WnvK585oUTOMFAUpGMZBn5AoXRglGHptzeIBWk2ceQM4vWkwipv2pLXc3V6ySrOyJxlZQuioXQ3QlmqHE1APEWmw4UgJwV5zS045X22aMKmp4dyXm4j6r1HXiFKOUFIG4rgXyme~OEXnIH-zNuzDJhpA__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "8",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia Golden Mix 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 23955.96,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 23955.96,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "3",
        name: "PVP",
        type: "amount",
        price: 21848,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 23955.96,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 11346,
      availableQuantity: 997709,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 997709,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
      {
        id: "6",
        name: "ICUI",
        percentage: "20.00",
        description: "ICUI",
        status: "active",
        deductible: "yes",
        type: "ICUI",
        categoryFavorable: {
          id: "5013",
          idParent: "5010",
          name: "Otro tipo de impuesto a favor",
          text: "Otro tipo de impuesto a favor",
          code: null,
          description: null,
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "106",
            name: "Otro tipo de impuesto a favor",
            key: "OTHER_TAX_TYPE_IN_FAVOR",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5044",
          idParent: "5041",
          name: "Otro tipo de impuesto por pagar",
          text: "Otro tipo de impuesto por pagar",
          code: null,
          description: null,
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "105",
            name: "Otro tipo de impuesto por pagar",
            key: "OTHER_TAX_TYPE_TO_PAY",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categorySalesReturns: {
          id: "5044",
          idParent: "5041",
          name: "Otro tipo de impuesto por pagar",
          text: "Otro tipo de impuesto por pagar",
          code: null,
          description: null,
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "105",
            name: "Otro tipo de impuesto por pagar",
            key: "OTHER_TAX_TYPE_TO_PAY",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryPurchaseReturns: {
          id: "5013",
          idParent: "5010",
          name: "Otro tipo de impuesto a favor",
          text: "Otro tipo de impuesto a favor",
          code: null,
          description: null,
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "106",
            name: "Otro tipo de impuesto a favor",
            key: "OTHER_TAX_TYPE_IN_FAVOR",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: "ICUI_2025",
      },
    ],
    images: [
      {
        id: 4,
        name: "IMG_20200622_145613.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076848-IMG_20200622_145613.jpg?Expires=1738026650&Signature=odGBJenoEWBGwU1uv9MwDqIZbS3hm-LSarE3PGcm4NtnWDK632LtUtsPEKYyfQArxPfrkFLgLzkVYMarop2ITl-7mtavbL~sQmL5LlVCic38FnrYbekyUXVkqS-VbCcdrCIjtW4oWaguM0j7eQEABAlYzJmcG8Rm02hsikh9Jb5tE3JaSrOC-t6HkkmmwS-KCy~pmW1VDwd8FIvB2L01YebZ~EYcB~bVd2MZ1UnU~SSRcnd0tWRv4taXJGAhBiPACtu0DTzifZlo1y7-XfZSJ9UWfnxW0vPGqTqQ1e7r~xVNnEghXv1vbUdRCZqGsY~XQfktL0OLrIdWjcrNjzQUOA__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 11,
        name: "Imagen2.png",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594573971-Imagen2.png?Expires=1738026650&Signature=BE1u07tPmDsu4wZKXK2Gy7wRrcY-R4YSQtPt1EO1VUcwQq0-A51v1-6M8zb39P2wXVpycKnsykXmJ5RxF6HxZ4uDCZamD~aDovmTEGj82hHR7LuLMelFQABdMIZVYZ16Y5At6U0qooLsevIU3OeayKgQ-TC6gAOEMkh2X2FVcU8eGoGBGRJrTw3OMddFoAKRVfdLkGaprhRtLyAjsz42Mg6q-c93tlRU3hi56tjprwtoz9Qw3EjzRY5-v-eJxsDd9NonCydI1wik2WYHSpxpDFLagjJ~cZC5het6PI8HCh~6JLinv03V~Wgj8YwHvq2nM2d19dTEwztyHFvsPehobQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 15,
        name: "macadamia-golden_eb7e5ceb.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575306-macadamia-golden_eb7e5ceb.jpg?Expires=1738026650&Signature=gW9zBmjsLzUUUdzMH8sJBLWmplgAxEG0Jq57ZrgehXSeVrcvjU4K1Vc59TdBQSSy8NOy2wwV7Gn7g-WzPNENXOuO53xodL8ScFBf07DjKWv6IP7WPIpn~yPzCfSxzh3yD9krbvlET9auxvtu8neYFBBhtL78sUf0Mo2eyItw2PVOabEQMwDcrHaNFNJz7RIj7BmiVc1VlbabjzkhKrKgykiOOErbdc0hnwJk5MagUKokNtEKLL0-9jsv49~boM1BVqrkvPh~AIU4O5qTSOullyk56QgJvtXFAsw94JDD6y76GLxQY3ehSvcqtfQeBOrey7mWRHp8OJ5KAeVGNA5alQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "2",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia 230g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 35126.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 34622.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "3",
        name: "PVP",
        type: "amount",
        price: 32857,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 35126.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-04",
      unit: "unit",
      unitCost: 18583,
      availableQuantity: 965058,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 965058,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 3,
        name: "Macadamia.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076829-Macadamia.jpg?Expires=1738026650&Signature=TrOExN9-YfDFrUvCWGyyUaEpf7mIHbSCS-auc1-ktTZlE3qB4BbkCeOkRaqzP0fNL3rJ8c2mDkjcNjUsxJ3gzALZNknxBurXnyV-0Oa8VmhMcQSnn~c2n3tId6xEGv7P0h7RKMMPQwliIedByUwrFq-a8AuBU7NL9tNRjaU~CV~Fvh4jwyrlqoev4ZU~-pVe4Me0Av~zDWgpw01JYdsrqyu3kh0r64nNPREqwcO6wKSU4xjwFVWZgWBtypZ7ddyj3hS7ulbQYmdme9W~VOjo3z2yTUTj0uDgQEBPZT~ZYtBpCYZ~DPZ7fxKXiWa~lYO6NO8i91NKRSBcjKPgXllmEQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 14,
        name: "macadamia_2eff4f33.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575296-macadamia_2eff4f33.jpg?Expires=1738026650&Signature=WpG9MGQclCbXytsh0LOAhxEjG4rQHDdOduEV9WKYba105S-w3fziBbbS8Bc-X28tgXrBN1jMiWJqhdKqx3Z5V-RqS8IXfbfmcB6yXeMyj0YTNsazYkjRO-lyKevdjV6LOFFD5tjl5q7dmSYaGUV6zb8lI2VnLxfie0OtZYpu1Vzib0tsf0TWK78G8-vzDKILHn0eBxL9pvZCruw-SjmthaWlAcscIHorYICr~tYPJLMYLvrCcUAM9BA5vmZ4IoCA8xl6-ZwLS~hS~8D62hpkuXh84XuUfvbeGzfCrUfcZR3GAK27puY-Hp-u0LnZesRh6nIPtHNJd1NMixsu8XmHXg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "1",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Almendras 230g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 30840.4,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: "2",
        name: "Salvatore",
        type: "amount",
        price: 28067.4,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "3",
        name: "PVP",
        type: "amount",
        price: 28571,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: "4",
        name: "2020 general",
        type: "amount",
        price: 30840.4,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-04",
      unit: "unit",
      unitCost: 13588,
      availableQuantity: 961061,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 961061,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 2,
        name: "almendra.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076804-almendra.jpg?Expires=1738026650&Signature=rAiM7BvODWUV0Od83wxBD9y-oveUZFFVfs0hC5dLoFQ0kr38XZFarDGCyeV7nbpQD37GpxKRT4m7yGYaws8MymXgvd2T8AnXuECdW8mJXe~zsj8MaLMLMqe8zmUGrgH0uW8f1LfgEkuxpqDHpvDCKzG2yybk4X~FgWD6qHV5kpdiLW~uogRXWJRzFTnK7wBDUzfKuSTBx-2bcXSp59Ds-NMstBkqj94e4gEPT9vMdpGNGFDiYtxXcOo0EKWRULyx-GHUNHZ-5nrSyp9WGDYgDNVuGRACXHWzAF0m5F3p4b4UCytVLcj3~FK3GEQxlXtJHxtSQPcTyDN36-qVQ5BmbQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 13,
        name: "almendra_63f7dee4.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575284-almendra_63f7dee4.jpg?Expires=1738026650&Signature=UCEYc2xX5i~bFx7tOXyNIreBdh6EW7J0c-nU0-op4LXTwHJ2LsCuM0fNhwgCjIFG9NRYZW8rGhZAc-QL2t0z2bZD2cn~qjZ1lqcj7lISSUu1N9M9AhQ6A89mcPoZxvqSQtd4um72dKdJZq-78SteY9ekQOGs5hFDR8EuxB2~FdhruEZub0tssQIY-EPRVeqnkfS0zT6B-HkK3TjoNRD7Q56Gu5jEjgjeOzHnZGzqxNr7rdgOtZOmdSk-5zwaHJN~ifXwPb91LSc~7i3kjY6~0E9UZF6rQuHx8Cdv-UTUkjoeWnDswgBv95pXIbcpZfulDsLEHdpnubkW8OOnJa94oQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: "11",
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Almendras 1kg",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 6,
    price: [
      {
        idPriceList: "1",
        name: "General",
        type: "amount",
        price: 60672,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 1000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 33422,
      availableQuantity: 928,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000,
          availableQuantity: 928,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        deductible: "yes",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "simple",
    itemType: null,
  },
] as const;

export const PROD_IMAGES = {
  1: imgAlmendra,
  2: imgMacadamia,
  3: imgMaranon,
  5: imgCocoa,
  4: imgMani,
  6: imgPink,
  7: imgMatcha,
  8: imgGolden,
  13: imgCanela,
  16: imgMaranon,
  17: imgMacadamia,
  18: imgAlmendra,
  19: imgMani,
  22: imgCocoa,
  23: imgCanela,
  24: imgPink,
  25: imgPistacho,
} as { [key: number]: string };

export const DISCOUNTS = [500]; // [13, 3, 5];
export const DISCOUT_PERCENTGE = 15;
export const hasDiscount = (id: number): boolean => DISCOUNTS.includes(id);

const notAProductId = [21, 20]; //insolvencia y otras cosas de Diego mr. accountant

export const PRODS = THE_PRODS.map((i) => ({ ...i, id: Number(i.id) }))
  .filter((i) => !notAProductId.includes(i.id))
  .map((i) => ({
    ...i,
    name: i.name.replace("Ã­ ", "í ").replace("Ã±Ã³", "ñó").replace("Ã¡", "á"),
  }));
